const data = {
  production: {
    LIQUIDITY_URL: 'https://liquidity.wardenswap.finance',
    FARM_URL: 'https://farm.wardenswap.finance',
    NFT_URL: 'https://wardenswap.finance/nft',
    DASHBOARD_URL: 'https://wardenswap.finance/dashboard/#/'
  },
  alpha: {
    LIQUIDITY_URL: 'https://alpha-liquidity.wardenswap.finance',
    FARM_URL: 'https://alpha-farm.wardenswap.finance',
    NFT_URL: 'https://alpha.wardenswap.finance/nft',
    DASHBOARD_URL: 'https://alpha.wardenswap.finance/dashboard/#/'
  },
  local: {
    LIQUIDITY_URL: 'https://alpha-liquidity.wardenswap.finance',
    FARM_URL: 'https://alpha-farm.wardenswap.finance',
    NFT_URL: 'https://alpha.wardenswap.finance/nft',
    DASHBOARD_URL: 'https://alpha.wardenswap.finance/dashboard/#/'
  }
}
type Stages = 'local' | 'alpha' | 'production'
export default data[process.env.VUE_APP_ENV as Stages || 'production']
