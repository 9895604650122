const data = {
  RPC_OFFICIAL_URL: 'https://matic-mainnet.chainstacklabs.com',
  RPC_WARDEN_URL: 'https://polygon-mainnet.infura.io/v3/b9ab4f4e34134dd8b3f0e87c5216b45b',
  GAS_TRACKER_URL: 'https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle',
  BLOCK_EXPLORER_URL: 'https://polygonscan.com',
  WARDEN_EXCHANGE_STAT_S3_URL: 'https://w-exchange-stat-polygon.s3.ap-southeast-1.amazonaws.com',
  GITHUB_ASSET_IMAGE_URL: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets',
  WARDEN_ROUTING_CONTRACT_ADDRESS: '0x030B8b7bF245E762736e65c0903295447B898c30',
  DEFAULT_GAS_PRICE: '200000000000', // 200 Gwei
  MIN_NATIVE_TOKEN_SWAP: 0.005, // 0.005 MATIC
  NATIVE_TOKEN: {
    name: 'MATIC Token',
    symbol: 'MATIC',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 137,
    decimals: 18
  },
  WRAPPED_TOKEN: {
    name: 'Wrapped Matic',
    symbol: 'WMATIC',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: 137,
    decimals: 18
  },
  STABLE_COIN_TOKEN: {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    chainId: 137,
    decimals: 6
  }
}
export default data
