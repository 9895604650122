import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { SwapState, SwapModule } from '../store'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import Bignumber from '@/utils/bignumber'
import { Contract, utils, ethers } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { TransactionResponse } from '@ethersproject/providers'
import { AbstractSentryView } from '@/features/Sentry/abstractView'
import { AbstractAmplitudeView } from '@/features/Amplitude/abstractView'
import { groupTokenAmountFromBestRateData } from '../utils'
import { genGasFeeDataFromSelectedGasSpeed } from '@/features/Web3Connector/utils'
import { Network, GasfeeData, GasSpeed, ChainIDThatSupport } from '@/features/Web3Connector/types'
import {
  Token,
  TokenInput,
  CurrentBestRateSdk,
  TradeState
} from '@/types'
import {
  BestRateResultSdk,
  ErrorMessage as ErrorMessageSwap,
  WardenBestRateSdkTagVersion,
  WardenswapSdkVersion
} from '../types'
import { PARTNER_ID, NETWORK_CONSTANT } from '@/constants'
import { calculateGasMargin } from '@/utils/helper'

@Component
export class Swap extends Mixins(Vue, AbstractSentryView, AbstractAmplitudeView) {
  @SwapState public readonly allToken!: Token[]
  @SwapState public readonly tokensBalance!: { [key: string]: any }
  @SwapState public readonly tokenAInput!: TokenInput
  @SwapState public readonly tokenBInput!: TokenInput
  @SwapState public readonly currentBestRate!: CurrentBestRateSdk[]
  @SwapState public readonly bestRateResultSdk!: BestRateResultSdk
  @SwapState public readonly priceSlippage!: string
  @SwapState public readonly priceImpact!: string
  @SwapState public readonly referralId!: string
  @SwapState public readonly metaData!: number

  @Web3ConnectorState public readonly ethersProvider!: ethers.providers.Web3Provider
  @Web3ConnectorState public readonly network!: Network | 'disconnected'
  @Web3ConnectorState public readonly networkId!: number
  @Web3ConnectorState public readonly gasFeeData!: GasfeeData
  @Web3ConnectorState public readonly userAddress!: string | null
  @Web3ConnectorState public readonly wardenBestRateSdk1!: WardenBestRateSdkTagVersion | null
  @Web3ConnectorState public readonly isWalletProviderSupportEip1559!: boolean | null
  @Web3ConnectorState public readonly selectedGasSpeed!: GasSpeed

  public trade(): Promise<TransactionResponse | Error> {
    SwapModule.setTradeState(TradeState.UNKNOWN)

    try {
      return this.callMethodSwapNewVersion()
    } catch (error) {
      SwapModule.setTradeState(TradeState.ERROR)
      throw error
    }
  }

  private getPartnerIdForSwap() {
    if (this.referralId && [ChainIDThatSupport.bsc].includes(this.networkId)) {
      return this.referralId
    }

    return PARTNER_ID
  }

  private async callMethodSwapNewVersion() {
    try {
      SwapModule.setTradeState(TradeState.PENDING)
      const tokenAChecksumAddress = getAddress(this.tokenAInput.address as string)
      const tokenBChecksumAddress = getAddress(this.tokenBInput.address as string)
      const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address)
      const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address)
      const { amountIn: amountAInBase, amountOut: amountBInBase } = groupTokenAmountFromBestRateData(this.currentBestRate)
      let amountAInWei = utils.parseUnits(amountAInBase, tokenAData?.decimals)
      if (
        this.networkId === ChainIDThatSupport.arbitrum &&
        tokenAChecksumAddress !==
          getAddress(NETWORK_CONSTANT[this.networkId as ChainIDThatSupport.arbitrum].NATIVE_TOKEN.address) &&
        utils.parseUnits(this.tokensBalance[this.tokenAInput.address as string], tokenAData?.decimals).eq(amountAInWei)
      ) {
        amountAInWei = amountAInWei.sub(1)
      }
      const amountBInWei = utils.parseUnits(amountBInBase, tokenBData?.decimals)
      const minDestAmount = Bignumber(amountBInWei.toString())
        .times(Bignumber(100).minus(this.priceSlippage))
        .idiv(100)
        .toString(10)
      const partnerId = this.getPartnerIdForSwap()
      const signer = this.ethersProvider.getSigner()
      if (this.bestRateResultSdk.sdkVersion === WardenswapSdkVersion.NEW_VERION_2) {
        console.log(
          'Log data before wrap in args',
          JSON.stringify(
            {
              tokenAChecksumAddress,
              tokenBChecksumAddress,
              minDestAmount,
              senderAddress: this.userAddress,
              priceSlippage: `${this.priceSlippage}%`,
              partnerId,
              metaData: this.metaData,
              'User input amount in base': amountAInBase,
              'User input amount in wei': amountAInWei.toString(),
              'currentBestRate amount out in base': amountBInBase,
              'currentBestRate amount out in wei': amountBInWei.toString()
            },
            null,
            2
          )
        )
        const gasFeeDataFromSelectedGasSpeed = genGasFeeDataFromSelectedGasSpeed(this.gasFeeData, this.selectedGasSpeed, this.isWalletProviderSupportEip1559 as boolean, this.networkId)
        const transactionResponse = await (this.wardenBestRateSdk1 as WardenBestRateSdkTagVersion).swap(
          signer,
          tokenAChecksumAddress,
          tokenBChecksumAddress,
          amountAInWei.toString(),
          minDestAmount,
          this.bestRateResultSdk,
          partnerId,
          this.metaData,
          ethers.constants.AddressZero,
          { ...gasFeeDataFromSelectedGasSpeed }
        )
        return transactionResponse
      } else {
        throw `SDK version ${this.bestRateResultSdk.sdkVersion} not suport for swap`
      }
    } catch (error) {
      if (error?.code === 4001) {
        SwapModule.setTradeState(TradeState.REJECTED)
        this.amplitudeLogEvent('User denied transaction signature (Trade)', {
          senderAddress: this.userAddress,
          tokenAInputAddress: this.tokenAInput.address,
          tokenAInputSymbol: this.tokenAInput.symbol,
          tokenAInputAmount: this.tokenAInput.amount,
          tokenBInputAddress: this.tokenBInput.address,
          tokenBInputSymbol: this.tokenBInput.symbol,
          tokenBInputAmount: this.tokenBInput.amount,
          priceImpact: this.priceImpact
        })
        throw new Error(ErrorMessageSwap.TRANSACTION_REJECTED)
      } else {
        SwapModule.setTradeState(TradeState.ERROR)
        this.sentryLogError(this.network, error, 'Error')
        console.error('Swap failed', error)
        throw new Error(`Swap failed\n ${error.message}`)
      }
    }
  }

  public estimateGasForContract(contract: Contract, methodName: string, args: Array<any>): Promise<string | Error> {
    // @ts-ignore
    return contract.estimateGas[methodName](...args)
      .then((gasEstimate: any) => {
        return calculateGasMargin(gasEstimate.toString())
      })
      .catch((gasError: any) => {
        console.log('Sender address', this.userAddress)
        console.log('estimateGasForContract args', JSON.stringify(args))
        // ---------------
        this.sentryLogError(this.network, gasError, 'Error')
        console.error(`EstimateGas error for method ${methodName}`, gasError)
        throw new Error(gasError?.data?.message || gasError.message)
      })
  }
}
