




















































































































































































































































































import { Component, Ref, Mixins } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import ConnectWalletButton from './components/ConnectWalletButton.vue'
import WrongNetworkNotice from './components/WrongNetworkNotice.vue'
import ContactInfo from '../../components/ContactInfo.vue'
import SwitchNetworkDropdown from '@/features/SwitchNetwork/SwitchNetworkDropdown.vue'
import SwitchNetworkAndConnectWalletModal from '@/features/SwitchNetwork/SwitchNetworkAndConnectWalletModal.vue'
import { ChainIDThatSupport, Network } from '@/features/Web3Connector/types/Web3'
import SettingsModal from '@/components/SettingsModal.vue'
import BuyWardenTokenCard from '@/components/BuyWardenTokenCard.vue'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'
import envconstants from '@/constants/envirionment'

@Component({
  components: {
    ConnectWalletButton,
    WrongNetworkNotice,
    ContactInfo,
    SettingsModal,
    SwitchNetworkDropdown,
    SwitchNetworkAndConnectWalletModal,
    BuyWardenTokenCard
  },
  computed: mapGetters(['mute', 'theme'])
})

export default class Header extends Mixins(AbstractSwapView, AbstractWeb3ConnectorView) {
  @Ref() readonly SettingsModal!: SettingsModal
  @Ref() readonly SwitchNetworkDropdown!: SwitchNetworkDropdown
  @Ref() readonly SwitchNetworkAndConnectWalletModal!: SwitchNetworkAndConnectWalletModal

  isOpen = false
  NetworkType = Network
  mute?: boolean
  theme?: string
  liquidityUrl = envconstants.LIQUIDITY_URL
  farmUrl = envconstants.FARM_URL
  nftUrl = envconstants.NFT_URL
  dashboardUrl = envconstants.DASHBOARD_URL

  drawer() {
    this.isOpen = !this.isOpen
  }

  get imgLogo() {
    if (this.theme === THEME.SPACE_MODE) {
      if (this.networkId === ChainIDThatSupport.polygon) {
        return require('@/assets/svg/warden-logo-2-bloodstone-polygon-space.svg')
      } else if (this.networkId === ChainIDThatSupport.arbitrum) {
        return require('@/assets/svg/warden-logo-2-arbitrum-space.svg')
      } else if (this.networkId === ChainIDThatSupport.ethereum) {
        return require('@/assets/svg/warden-logo-2-bloodstone-ethereum-space.svg')
      } else if (this.networkId === ChainIDThatSupport.avalanche) {
        return require('@/assets/svg/warden-logo-2-bloodstone-avalanche-space.svg')
      } else if (this.networkId === ChainIDThatSupport.optimism) {
        return require('@/assets/svg/warden-logo-2-bloodstone-optimism-space.svg')
      }
      return require('@/assets/svg/warden-logo-2-bloodstone-space.svg')
    }
    if (this.networkId === ChainIDThatSupport.polygon) {
      return require('@/assets/svg/warden-logo-2-bloodstone-polygon.svg')
    } else if (this.networkId === ChainIDThatSupport.arbitrum) {
      return require('@/assets/svg/warden-logo-2-arbitrum.svg')
    } else if (this.networkId === ChainIDThatSupport.ethereum) {
      return require('@/assets/svg/warden-logo-2-bloodstone-ethereum.svg')
    } else if (this.networkId === ChainIDThatSupport.avalanche) {
      return require('@/assets/svg/warden-logo-2-bloodstone-avalanche.svg')
    } else if (this.networkId === ChainIDThatSupport.optimism) {
      return require('@/assets/svg/warden-logo-2-bloodstone-optimism.svg')
    }
    return require('@/assets/svg/warden-logo-2-bloodstone.svg')
  }

  get imgSetting() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-setting-space.svg')
    }

    return require('@/assets/svg/icon-setting.svg')
  }

  get imgHamburger() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-hamberger-menu-space.svg')
    }

    return require('@/assets/svg/icon-hamberger-menu.svg')
  }

  get query() {
    return `?t=${this.theme === THEME.SPACE_MODE ? '1' : '0'}&s=${this.mute ? '1' : '0'}`
  }
}
