import { WardenBestRate as WardenBestRateNewSdk, QuoteResult as QuoteResultNewSdk } from '@wardenswap/bestrate-sdk'
import { ApprovalTransactionStatus, ApprovalState } from '@/types'

export enum WardenswapSdkVersion {
  NEW_VERION_2 = 'v2.1.1', // Should update when system update sdk version
}

export type GetQuoteRetrunTypeFromSdk = QuoteResultNewSdk

export type WardenBestRateSdkTagVersion = WardenBestRateNewSdk & { sdkVersion: WardenswapSdkVersion.NEW_VERION_2 }

export type BestRateResultSdk = GetQuoteRetrunTypeFromSdk & { sdkVersion: WardenswapSdkVersion.NEW_VERION_2 }

export enum ErrorMessage {
  TRANSACTION_REJECTED = 'Transaction rejected',
}

export type SwapGasFees = {
  usd: string;
  nativeTokenInBase: string;
}

export interface GroupTokenAmount {
  amountIn: string;
  amountOut: string;
}

export type WrapResourceBestRateFromSdk = {
  value: GetQuoteRetrunTypeFromSdk;
  sdkType: string;
  sdkVersion: WardenswapSdkVersion;
}

export type TokenApprovalTransaction = {
  tokenAddress: string;
  txHash: string;
  txStatus: ApprovalTransactionStatus;
  approvalState: ApprovalState;
}

export interface TokensBalance {
  [key: string]: string;
}
