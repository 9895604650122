






























































































































import { Mixins, Component, Watch, Prop } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Modal } from '@/classes/modal'
import { getAddress } from '@ethersproject/address'
import { NETWORK_CONSTANT } from '@/constants'
import { TradeState, TransactionReceiptData } from '@/types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'
import { getTokenImageFromUrl } from '@/resources/thirdPartyApi'

@Component({
  name: 'WaitingConfirmSwapModal',
  computed: mapGetters(['theme'])
})

export default class WaitingConfirmSwapModal extends Mixins(AbstractSwapView, Modal) {
  theme?: string
  @Prop({ default: '' }) readonly errorMessage!: string

  TradeState = TradeState
  headerText = ''
  txHash = ''
  transactionReceiptData = {} as TransactionReceiptData
  isAddTokenToMetaMaskSuccess = false

  setTxHash(txHash: any) {
    this.txHash = txHash
  }

  setTransactionReceiptData(transactionReceiptData: TransactionReceiptData) {
    this.transactionReceiptData = transactionReceiptData
  }

  openLinkExplorerNewTab() {
    const uri = `${NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].BLOCK_EXPLORER_URL}/tx/${this.txHash}`
    window.open(uri, '_blank')
    this.amplitudeLogEvent('Click open link explorer new tab in confirm swap modal', { uri })
  }

  async handleAddToken() {
    this.amplitudeLogEvent('Click add token to metamask', { tokenAddress: this.transactionReceiptData.destAssetData.address })
    const githubAssetImageUrl = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].GITHUB_ASSET_IMAGE_URL
    let imageUrl: string | undefined = `${githubAssetImageUrl}/${getAddress(this.transactionReceiptData.destAssetData.address)}/logo.png`
    try {
      await getTokenImageFromUrl(imageUrl)
    } catch (error) {
      imageUrl = undefined
    }
    try {
      const isSuccess = await this.addTokenInMetaMask(this.transactionReceiptData.destAssetData, imageUrl)
      if (isSuccess) {
        this.isAddTokenToMetaMaskSuccess = true
        this.amplitudeLogEvent('Add token to metamask success', { tokenAddress: this.transactionReceiptData.destAssetData.address })
      }
    } catch (error) {
      this.amplitudeLogEvent('Add token to metamask failed', { tokenAddress: this.transactionReceiptData.destAssetData.address })
    }
  }

  @Watch('isModalOpen')
  resetTxHash(val: boolean) {
    if (!val) {
      this.txHash = ''
      this.transactionReceiptData = {} as TransactionReceiptData
      this.isAddTokenToMetaMaskSuccess = false
      this.amplitudeLogEvent('WaitingConfirmSwapModal is open')
    } else {
      this.amplitudeLogEvent('WaitingConfirmSwapModal is close')
    }
  }

  @Watch('tradeState')
  async handleTradeStateChange(status: TradeState) {
    switch (status) {
      case TradeState.PENDING:
        this.headerText = 'Waiting for confirm in your wallet'
        break
      case TradeState.WAIT_TX_CONFIRM:
        this.headerText = 'Transaction submitted'
        break
      case TradeState.SUCCESS:
        this.headerText = 'Transaction success'
        break
      case TradeState.FAIL:
        this.headerText = 'Transaction failed'
        break
      case TradeState.ERROR:
        this.headerText = 'Transaction Error'
        break
      default:
        this.headerText = ''
    }
  }

  get swapStatusPending() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/animation/swap-status-pending-space.svg')
    }

    return require('@/assets/animation/swap-status-pending.svg')
  }

  get blockExplorerTitle() {
    switch (this.networkId) {
      case ChainIDThatSupport.bsc:
        return 'BSC Scan'
      case ChainIDThatSupport.polygon:
        return 'Polygon Scan'
      case ChainIDThatSupport.arbitrum:
        return 'Arbiscan'
      case ChainIDThatSupport.ethereum:
        return 'Etherscan'
      case ChainIDThatSupport.avalanche:
        return 'Snowtrace'
      case ChainIDThatSupport.optimism:
        return 'optimistic etherscan'
      default:
        return 'View in Exploer'
    }
  }

  get iconBsc() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-bsc-link-on-modal-space.svg')
    }

    return require('@/assets/svg/icon-bsc-link-on-modal.svg')
  }

  get shouldDisplayAddTokenToMetaMask() {
    const isMetaMask = window?.ethereum?.isMetaMask ?? false
    const isConnectWalletMetaMask = this.walletName === 'MetaMask'
    const isNotNativeToken = this.transactionReceiptData?.destAssetAddress &&
      (getAddress(this.transactionReceiptData?.destAssetAddress) !== getAddress(NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN.address))
    return isMetaMask && isConnectWalletMetaMask && isNotNativeToken
  }
}
