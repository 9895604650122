import { wadTokenfeeDiscountTear } from './ethereumFeeDiscount'

const data = {
  RPC_OFFICIAL_URL: 'https://mainnet.infura.io/v3/c13383d14bf94e369a9ad28c6cb297cc',
  RPC_WARDEN_URL: 'https://warden-node.wardenswap.finance/rpc/eth',
  BLOCK_EXPLORER_URL: 'https://etherscan.io/',
  WARDEN_EXCHANGE_STAT_S3_URL: 'https://w-exchange-stat-ethereum.s3.ap-southeast-1.amazonaws.com',
  GITHUB_ASSET_IMAGE_URL: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets',
  WARDEN_ROUTING_CONTRACT_ADDRESS: '0x39f97198c5DbC193EB962c4B3B7e447091A18eAB',
  MIN_NATIVE_TOKEN_SWAP: 0.009, // 0.009 ETH ~$20
  SWAP_FEE_DISCOUNT: {
    wadTokenfeeDiscountTear
  },
  NATIVE_TOKEN: {
    name: 'ETH Token',
    symbol: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 1,
    decimals: 18
  },
  WRAPPED_TOKEN: {
    name: 'Wrapped Ether Token',
    symbol: 'WETH',
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    chainId: 1,
    decimals: 18
  },
  STABLE_COIN_TOKEN: {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
    chainId: 1
  },
  WARDEN_TOKEN: {
    name: 'Warden Token',
    symbol: 'WAD',
    address: '0x0B6F3c17e1626a7cBfA4302CE4E3c45522d23A83',
    chainId: 1,
    decimals: 18
  }
}
export default data
