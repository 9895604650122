import { optiPunkNFTfeeDiscountTear } from './optiPunkFeeDiscount'
import { wadTokenfeeDiscountTear } from './optimismFeeDiscount'

const data = {
  RPC_OFFICIAL_URL: 'https://mainnet.optimism.io',
  RPC_WARDEN_URL: 'https://optimism-mainnet.infura.io/v3/a388e8fd06ad49ea81aae241495086f5',
  BLOCK_EXPLORER_URL: 'https://optimistic.etherscan.io/',
  WARDEN_EXCHANGE_STAT_S3_URL: 'https://w-exchange-stat-optimism.s3.ap-southeast-1.amazonaws.com',
  GITHUB_ASSET_IMAGE_URL: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/optimism/assets',
  WARDEN_ROUTING_CONTRACT_ADDRESS: '0x7EA8c22E6Dcd7bd69eb180664Da68e1f1F11D696',
  OPTIPUNK_CONTRACT_ADDRESS: '0xB8Df6Cc3050cC02F967Db1eE48330bA23276A492', // OptiPunk NFT
  OPTIPUNK_NFT_IMAGE_S3_URL: 'https://image.wardenswap.finance/optipunk-nft',
  TOP_OPTIPUNK_CLASS_CONTRACT_ADDRESS: '0x338f48B6c18A49Fc7DD7137498617d06A5e089C7',
  MIN_NATIVE_TOKEN_SWAP: 0.005, // 0.005 ETH
  SWAP_FEE_DISCOUNT: {
    optiPunkNFTfeeDiscountTear,
    wadTokenfeeDiscountTear
  },
  NATIVE_TOKEN: {
    name: 'ETH Token',
    symbol: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 10,
    decimals: 18
  },
  WRAPPED_TOKEN: {
    name: 'Wrapped Ether',
    symbol: 'WETH',
    address: '0x4200000000000000000000000000000000000006',
    chainId: 10,
    decimals: 18
  },
  STABLE_COIN_TOKEN: {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    decimals: 6,
    chainId: 10
  },
  WARDEN_TOKEN: {
    name: 'WardenSwap Token',
    symbol: 'WAD',
    address: '0x703D57164CA270b0B330A87FD159CfEF1490c0a5',
    decimals: 18,
    chainId: 10
  }
}
export default data
