const data = {
  RPC_OFFICIAL_URL: 'https://arb1.arbitrum.io/rpc',
  RPC_WARDEN_URL: 'https://arbitrum-mainnet.infura.io/v3/40eed867a4e3445985a99d4a10be2b4a',
  BLOCK_EXPLORER_URL: 'https://arbiscan.io',
  WARDEN_EXCHANGE_STAT_S3_URL: 'https://w-exchange-stat-arbitrum.s3.ap-southeast-1.amazonaws.com',
  GITHUB_ASSET_IMAGE_URL: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets',
  WARDEN_ROUTING_CONTRACT_ADDRESS: '0x79A556ef2c5b613dB3DFa8797E6772c5AAF86834',
  DEFAULT_GAS_PRICE: '4000000000', // 4 Gwei
  MIN_NATIVE_TOKEN_SWAP: 0.005, // 0.005 ETH
  NATIVE_TOKEN: {
    name: 'ETH Token',
    symbol: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 42161,
    decimals: 18
  },
  WRAPPED_TOKEN: {
    name: 'Wrapped Ether Token',
    symbol: 'WETH',
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    chainId: 42161,
    decimals: 18
  },
  STABLE_COIN_TOKEN: {
    name: 'USD Coin Arb1',
    symbol: 'USDC',
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    decimals: 6,
    chainId: 42161
  },
  WARDEN_TOKEN: {
    name: 'Warden Token',
    symbol: 'WAD',
    address: '0x6374D87C5A48c41b309a1Ab7b12EEB4fE30D8d8a',
    chainId: 42161,
    decimals: 18
  }
}
export default data
