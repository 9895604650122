




























































import { Component, Mixins, Watch, Emit, Prop, PropSync } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Modal } from '@/classes/modal'
import { filterTokens, isAddress } from '@/utils/helper'
import { Token } from '@/types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import TokenDetailWrap from '@/components/TokenDetailWrap.vue'
import AcceptCustomToken from '@/components/AcceptCustomToken.vue'
import { mapGetters } from 'vuex'
import * as _ from '@/utils/lodash-extended'

@Component({
  name: 'TokenListModal',
  components: {
    TokenDetailWrap,
    AcceptCustomToken
  },
  computed: mapGetters(['mute'])
})
export default class TokenListModal extends Mixins(AbstractSwapView, Modal) {
  mute?: boolean

  isModalOpen = false
  modalPosition = 'center'
  expectedToken: Token[] = []
  tokenForDisplayNextGroupKey = 15
  searchToken = ''
  isCustomToken = false
  isAcceptCustomToken = false

  @PropSync('selectedToken', { type: Object }) syncedSelectedToken!: object
  @Prop({ required: true }) readonly tokenType!: string

  onSearchTokenInput(val: any) {
    this.searchToken = val.target.value
  }

  trackSearchToken() {
    this.amplitudeLogEvent(`Input search ${this.tokenType}`, { text: this.searchToken })
  }

  playSoundEffect() {
    if (!this.mute) {
      let audio
      switch (this.networkId) {
        case ChainIDThatSupport.polygon :
          audio = document.getElementById('bgMusic-polygon') as HTMLAudioElement
          break
        case ChainIDThatSupport.arbitrum :
          audio = document.getElementById('bgMusic-arbitrum') as HTMLAudioElement
          break
        case ChainIDThatSupport.ethereum :
          audio = document.getElementById('bgMusic-eth') as HTMLAudioElement
          break
        case ChainIDThatSupport.avalanche :
          audio = document.getElementById('bgMusic-avalanche') as HTMLAudioElement
          break
        case ChainIDThatSupport.optimism :
          audio = document.getElementById('bgMusic-optimism') as HTMLAudioElement
          break
        default:
          audio = document.getElementById('bgMusic') as HTMLAudioElement
      }
      if (audio) {
        audio.play()
      }
    }
  }

  handleContinueBtn(token: Token) {
    this.isAcceptCustomToken = true
    this.amplitudeLogEvent('Click button accept import token', {
      tokenAddress: token.address,
      tokenName: token.name,
      tokenSymbol: token.symbol
    })
  }

  async showModal() {
    this.isModalOpen = true
    await this.getTokensBalance()
  }

  infiniteHandler($state: any) {
    this.tokenForDisplayNextGroupKey = this.tokenForDisplayNextGroupKey + 10
    if (this.tokenForDisplayNextGroupKey < this.expectedToken.length) {
      $state.loaded()
    } else {
      $state.complete()
    }
  }

  get tokenForDisplay(): Token[] {
    return this.expectedToken.slice(0, this.tokenForDisplayNextGroupKey)
  }

  created() {
    this.onSearchTokenChange()
  }

  @Emit()
  handleSelectedToken(token: Token) {
    this.playSoundEffect()
    this.handleTokenToVuexState(token)
    this.amplitudeLogEvent(`Selected ${this.tokenType}`,
      {
        tokenType: this.tokenType,
        tokenAddress: token.address,
        tokenBalanceBase: this.tokensBalance[token.address] ?? '0'
      }
    )
    this.isModalOpen = false
    return {
      token,
      tokenType: this.tokenType
    }
  }

  @Watch('searchToken', { immediate: true })
  async onSearchTokenChange() {
    this.isCustomToken = false
    const result = filterTokens(this.allToken, this.searchToken)
    this.expectedToken = result
    if (!result.length && isAddress(this.searchToken)) {
      try {
        const data = await this.getTokenInfo(this.searchToken) as Token
        this.isCustomToken = true
        this.expectedToken = [data]
        this.getTokensBalance([data])
      } catch (error) {
        console.error(error)
      }
    }
  }

  @Watch('computedAllToken', { immediate: true, deep: true })
  async onAllTokenChange(val: Token[], oldVal: Token[]) {
    if (val?.length !== oldVal?.length && this.expectedToken.length !== 1) {
      // Update expectedToken when user click decrease token
      this.expectedToken = this.allToken
    } else if (!_.isEqual(oldVal, val) && this.expectedToken.length !== 1) {
      // Update when token sort new order
      this.expectedToken = this.allToken
    }
  }

  @Watch('isModalOpen')
  resetSearchToken(val: boolean) {
    if (!val) {
      this.searchToken = ''
      this.amplitudeLogEvent(`Token list ${this.tokenType} modal is open`)
    } else {
      this.isCustomToken = false
      this.isAcceptCustomToken = false
      this.tokenForDisplayNextGroupKey = 15
      this.amplitudeLogEvent(`Token list ${this.tokenType} modal is close`)
    }
  }

  get computedAllToken() {
    return [...this.allToken]
  }
}
